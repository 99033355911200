import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Website Updates... Gatsby v3 and more",
  "date": "2021-03-24"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Alright, I'm back! I admit that I fell victim to the common plight of busy people: starting a project and then neglecting it after a few months. In my defence, the content was the most neglected. What happened was that working on this side project became more a way for me to keep up with writing and shipping code on the side while working as a full time product manager. Through this project, I have learned a lot about working with the `}<a parentName="p" {...{
        "href": "https://jamstack.org/"
      }}>{`JAM Stack`}</a>{`, using `}<a parentName="p" {...{
        "href": "https://netlify.com"
      }}>{`Netlify`}</a>{` for deployment, and using serverless functions, namely AWS Lambda.`}</p>
    <p>{`Since the last update, I've been incrementally fixing bugs and making aesthetic improvements. Recently, I updated the front end Gatsby framework to `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.com/blog/gatsby-v3/"
      }}>{`version 3`}</a>{` after hearing the announcement at Gatsbyconf 2021. It was a small effort that involved mostly updating underlying packages, but it resulted in noticeably faster build times which is always a plus. And it's nice to upgrade while it's easy to do so rather than falling many versions behind.`}</p>
    <p>{`Before that, I added a proper contact form that uses RECAPTCHA to filter out spam and an AWS Lambda function to deliver messages to my email.`}</p>
    <p>{`What's next? I'll probably add an RSS feed and a dark mode viewing. That said, I believe that the most important thing I should focus on is nailing down a more consistent practice of writing. I will have more life upates in the next few days.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      